import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/server",
  }),
  tagTypes: ["Auth", "Cart", "Item"],
  endpoints: (builder) => ({}),
});
